import moment from 'moment-timezone';
import _ from 'lodash';
import defaultURL from '../assets/static/images/image-placeholder.png'
import { DirectUpload } from 'activestorage';
import * as $ from 'jquery'
import html2canvas from '@nidi/html2canvas'
import jsPDF from 'jspdf'
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber'

import { API_ROOT } from '../../env';
import { change } from 'redux-form';
import { contactLensTypeFields } from './Constants';

import DiscoverCardImg from "../assets/static/images/discover-card.svg";
import VisaCardImg from "../assets/static/images/visa-card-logo.svg";
import MasterCardImg from "../assets/static/images/master-card.svg";
import AmericanExpressImg from "../assets/static/images/american-express-card.svg";
import UnionPayImg from "../assets/static/images/union-pay-card.svg";
import JCBImg from "../assets/static/images/jcb-card.svg";

// import missingImage from '../../assets/static/images/missing-img.svg';

// export function imageUrl(imageUrl) {
//   return imageUrl || missingImage;
// }

export function numberToCurrency(val){
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  var currency = formatter.format(val)
  return currency;
}
export function addPercentageSymbol(val){
 return `${val}%`
}

export function sanitizeNumberValue(val) {
  if (isNaN(Number(val))) {
    val = 0;
  }
  return val;
}

export function numberToCurrencyWithoutDecimal(val) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  var currency = formatter.format(val)
  return currency;
}

export function defaultImage(imgURL){
  if (imgURL && imgURL.file.url){
    return imgURL.file.url
  } else return defaultURL
}

export function getShortName(obj, imageClasses, className, notRounded) {
  const imageAttr = obj?.avatar_attributes || obj?.logo_attributes
  const nameArr = obj?.name?.trim().split(/\s+/) || obj?.company_name?.trim().split(/\s+/) || [];
  const firstName = nameArr[0] || '';
  const lastName = nameArr.length > 1 ? nameArr[nameArr.length - 1] : '';
  const lastNameOrLastChar = lastName || firstName.charAt(1);
  const initials = `${firstName.charAt(0).toUpperCase()}${lastNameOrLastChar.charAt(0).toUpperCase()}`;
  return imageAttr?.file?.url ?
    <img className={`${!notRounded && 'rounded-circle' } ${imageClasses}`} style={{ objectFit: "contain" }} src={imageAttr?.file?.url} alt="logo" />
    : <span className={`d-flex justify-content-center align-items-center text-primary bg-primary-light ${!notRounded && 'rounded-circle' } ${className}`}>
      {initials}
    </span>
}

export function getDefaultTextLogo(obj, imageClasses, textWrapperClasses, textClasses) {
  let logo_attributes = obj?.logo_attributes || obj?.logo
  return logo_attributes ? <img className={imageClasses} src={defaultImage(logo_attributes)} alt="logo" /> : <p className={`store-logo-text mx-auto bg-white ${textWrapperClasses}`}><span className={textClasses ? textClasses : 'p-1'}>{obj?.name}</span></p>
}

export function buildHashString(hash){
  var str= ""
  var arr = Object.keys(hash)
  arr.map(key => {
    if(hash[key])
      str = str + ", " + key
    }
  )
  return str.slice(1)
}

export function rangeBuilder(start, end, step, sign, zeroAs, uptoDecimal = 2, valBeforeZeroAs) {
  var data = []
  _.range(start, end, step).map((a) => {
    if (a === 0)
      data = valBeforeZeroAs ? [...data, { key: `${valBeforeZeroAs}` }, { key: `${zeroAs}` }] : [...data, { key: `${zeroAs}` }]
    else
      data = [...data, { key: `${a >= 0 ? sign + a.toFixed(uptoDecimal) : a.toFixed(uptoDecimal)}` }]
  })
  return data;
}

export function filterByKey(objs, key, val) {
  var filteredRecords = _.filter(objs, function (obj) {
    return (obj.attributes && obj.attributes[key]) ? obj.attributes[key] === val : obj[key] === val;
  });
  return filteredRecords;
}
export function findByKey(objs, key, val) {
  var foundedRecord = _.find(objs, function (obj) {
    return (obj.attributes && obj.attributes[key]) ? obj.attributes[key] === val : obj[key] === val;
  });
  return foundedRecord;
}
export function search(data, key, val ) {
  let matches = [];
  let regexp = new RegExp(val, 'i');
  data.forEach(item => {
    if (item && item[key].match(regexp)) matches.push(item);
  });
  return matches;
}

export function getKeyValues(objs, key) {
  var values = _.map(objs, function (obj) {
    return obj[key]
  });
  return values;
}

export function isPresentInArray(objs, key) {
  return _.includes(objs, key)
}

export function checkSubsetExistInArray(arr1, arr2) {
  return arr1.every( e => arr2.includes(e) )
}

export function removeSubsetFromArray(subset, array) {
  return array.filter((item) => !subset.includes(item));
}

export function buildOptions(data, textField, valueField){
  var options=[]
  if(data !== undefined && data !== null){
    data.map((item) => {
      options = [...options, {
          [textField]: (item.attributes && item.attributes[textField]) ? item.attributes[textField] : item[textField],
          [valueField]: (item.attributes && item.attributes[valueField]) ? item.attributes[valueField] : item[valueField]
        }]
    })
  }
  return [...options];  
}

export function dndReorder(dnd, records) {
  var temp = records[dnd.source.index]
  var positionableObj=[]
  if(dnd.destination!==null){
    if(dnd.source.index < dnd.destination.index){
      var totalMovements=dnd.destination.index-dnd.source.index 
      for (var index = 0; index < totalMovements; index++) {
        records[dnd.source.index+index] = records[dnd.source.index+index+1]
        positionableObj=[{id: records[dnd.source.index+index].id, position: dnd.source.index+index}, ...positionableObj]
      }
    }
    else{
      var totalMovements=dnd.source.index-dnd.destination.index 
      for (var index = 0; index < totalMovements; index++) {
        records[dnd.source.index-index] = records[dnd.source.index-index-1]
        positionableObj=[{id: records[dnd.source.index-index].id, position: dnd.source.index-index}, ...positionableObj]
      }
      
    }
    records[dnd.destination.index]=temp
    positionableObj=[{id: records[dnd.destination.index].id, position: dnd.destination.index}, ...positionableObj]
  }
  return({records, positionableObj})
}

export function openInNewTab(url) {
  var win = window.open(url, '_blank');
  win.focus();
}

export function humanize(str) {
  return _.capitalize(_.trim(_.snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')));
}

export function capitalize(str){
  return _.startCase(str);
}

export function handleNoData(data) {
  const output = data === '' || data === null ? '-' : data;
  return output;
}

export function handleNullObject(data) {
  const output = data === undefined || data === null ? '' : data.id;
  return output;
}

export function handleNullObjectName(data) {
  const output = data === undefined || data === null ? '' : data.name;
  return output;
}

export function formatPhoneNumber(phoneNumberString) {
  const phoneUtil = PhoneNumberUtil.getInstance()
  let valid = false;
  try {
   valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumberString))
  } catch(e) {
    valid = false;
  }
  if(!valid) {
    return phoneNumberString
  }

  const tel = phoneUtil.parse(phoneNumberString)
  const nationalFormat = phoneUtil.format(tel, PNF.NATIONAL)

  return nationalFormat
}

export function dateFormat(
  date,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const outputDate =
    date === '' || date === null || date === undefined
      ? '-'
      : moment.tz(date, timeZone).format('MM/DD/YYYY');
  return outputDate;
}

export function timeFormat(
  time,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const outputDate = moment.tz(time, timeZone).format('hh:mm A');
  return outputDate;
}

export function dateTimeFormat(
  date,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) {
  const outputDate =
    date === '' || date === null || date === undefined
      ? '-'
      : moment.tz(date, timeZone).format('MM/DD/YYYY hh:mm A z');
  return outputDate;
}

export function unixTimeFormat(unixTime) {
  const date = new Date(unixTime * 1000);
  return date.toLocaleDateString("en-US");
}

export function getNameFromHash(arr = [], selected_id = '') {
  let id = selected_id.id || selected_id;
  let output = '';
  arr.map(data => {
    if (id === data.id) {
      output = data.name;
    }
  });
  return output;
}

export function removeObject(dataArray, selectedId) {
  let arr = [];
  let id = '';
  dataArray.map(obj => {
    id = obj.id || obj;
    if (id !== undefined && id !== selectedId) arr = [...arr, obj];
  });
  return arr;
}

export function toggleId(dataArray = [], selectedId) {
  if (!_.includes(dataArray, selectedId)) {
    return [...dataArray, selectedId];
  }
  let arr = [];
  dataArray.map(obj => {
    if (obj !== undefined && obj !== selectedId) arr = [...arr, obj];
  });
  return arr;
}

var ranges = [
  { divider: 1e18, suffix: 'P' },
  { divider: 1e15, suffix: 'E' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'K' }
];

export function formatLargeNumber(n) {
  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      const value = Math.round((n / ranges[i].divider) * 100) / 100;
      return `${value.toString()} ${ranges[i].suffix}`;
    }
  }
  return Math.floor(n).toString();
}

export function numberWithCommas(inputNumber) {
  return new Intl.NumberFormat('en-US').format(inputNumber);
}

export function calculateHoursToMinutes(time) {
  let toNumsValue, hours, minutes, totalMinutes;

  toNumsValue = time.replace(/[^\d]/g, '');
  hours = time.slice(0, 2);
  minutes = toNumsValue.slice(2, 4);
  totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

  return totalMinutes;
}

export function imageUploadToS3(
  file,
  props = false,
  field = false,
  previewField = false
) {
  return new Promise((resolve, reject) => {
    if (props) {
      props.change(previewField, '/spinner.gif');
    }
    const upload = new DirectUpload(
      file,
      `${API_ROOT}/rails/active_storage/direct_uploads`
    );
    upload.create((error, blob) => {
      if (error) {
        alert('something went wrong with S3 upload!');
      } else {
        if (props) {
          props.change(field, blob.signed_id);
          props.change(previewField, blob.service_url);
        }
        resolve({ data: { ...blob, link: blob.service_url } });
      }
    });
  });
}

export function addressString(address){
  if(!(address == null || address === undefined)){
    var str="" 
    address = [ address.address1, address.address2, address.city, address.state, address.country, address.zip]
    address.map((val)=>{
      if(val!==null && val!==undefined) str += val+', '
    })
    
    return str.slice(0,-2)
  }
}

function setBackground(image){
  document.body.style.background = "url("+image+") no-repeat"
  document.body.style.backgroundAttachment= "fixed"
  document.body.style.backgroundSize = "100% 100%"
  if(image) document.body.style.opacity = "0.8"
  else document.body.style.opacity = "1"
}

export function getBackground(currentUser) {
  const { background_image_attributes, preferences: { background } } = currentUser
  var date = new Date()
  var hour = date.getHours();
  var month = date.getMonth();
  const backgroundData = process.env.REACT_APP_SUITE_NAME === "OWNER-SUITE" ?
    {
      background_mode: currentUser?.background_mode,
      background_url: currentUser?.background_url,
      backgrounds: currentUser?.backgrounds
    } :
    {
      background_mode: background?.background_mode,
      background_url: background?.background_url,
      backgrounds: background?.default_backgrounds
    }
  switch (backgroundData?.background_mode) {
    case 'time_of_day': {
      if (hour >= 12 && hour < 17) setBackground(backgroundData.backgrounds.afternoon_image_url)
      else if (hour >= 17 && hour < 24) setBackground(backgroundData.backgrounds.evening_image_url)
      else setBackground(backgroundData.backgrounds.morning_image_url)
      break
    }
    case 'season': {
      if (month >= 3 && month <= 5) setBackground(backgroundData.backgrounds.spring_image_url)
      else if (month >= 6 && month <= 8) setBackground(backgroundData.backgrounds.summer_image_url)
      else if (month >= 9 && month <= 11) setBackground(backgroundData.backgrounds.autumn_image_url)
      else setBackground(backgroundData.backgrounds.winter_image_url)
      break
    }
    case 'custom':
      setBackground(backgroundData?.background_url)
      break

    case 'upload':
      setBackground(background_image_attributes?.file.url)
      break

    default: setBackground()
  }
}

export function removeBackground(){
  document.body.style.backgroundImage = null
  document.body.style.opacity = null
}

export function initializePatientTemplateAttributes(attributes){
  const {
    basic_details: {fields: {age, email, gender, last_name, cell_phone, first_name, home_phone, 
      office_phone, date_of_birth,  mode_of_notification_type}},
    other_details: {fields: {ssn, employer, ethnicity, eye_color, occupation}}, 
    emergency_contact:{fields: {emergency_contact_name, emergency_contact_phone, emergency_contact_relation}},
    address_details: {home_address, office_address}, 
    knowledge_of_office: {fields: {who_refer_you, how_choose_you_office}},
    insurance_detail: { fields: { vision_insurance, medical_insurance, medical_tertiary_insurance, medical_secondary_insurance, vision_secondary_insurance, vision_tertiary_insurance, relation_to_insured}},
    primary_insurer, primary_insurer_address, primary_physician } = attributes
  var patientData = {
    "first_name": first_name.default_value,
    "last_name": last_name.default_value,
    "cell_phone": cell_phone.default_value,
    //"age": age.default_value,
    "home_phone": home_phone.default_value,
    "office_phone": office_phone.default_value,
    "email": email.default_value,
    "date_of_birth": date_of_birth.default_value,
    "gender": gender.default_value,
    "ethnicity": ethnicity.default_value,
    "occupation": occupation.default_value,
    "eye_color": eye_color.default_value,
    "employer": employer.default_value,
    // "mode_of_notification": mode_of_notification.default_value,
    "mode_of_notification_type": mode_of_notification_type.default_value,
    "ssn": ssn.default_value,
    "referred_by": who_refer_you.default_value,
    "knowledge_of_office": how_choose_you_office.default_value,
    "emergency_contact_name": emergency_contact_name.default_value,
    "emergency_contact_phone": emergency_contact_phone.default_value,
    "emergency_contact_relation": emergency_contact_relation.default_value,

    "primary_insurer": {
      "first_name": primary_insurer.fields.first_name.default_value,
      "last_name": primary_insurer.fields.last_name.default_value,
      "date_of_birth": primary_insurer.fields.date_of_birth.default_value,
      "gender": primary_insurer.fields.gender.default_value,
      "address_attributes": {
        "state": primary_insurer_address.fields.state.default_value,
        "address1": primary_insurer_address.fields.address1.default_value,
        "address2": primary_insurer_address.fields.address2.default_value,
        "country": primary_insurer_address.fields.country.default_value,
        "zip": primary_insurer_address.fields.zip.default_value,
        "city": primary_insurer_address.fields.city.default_value,
      }
    },
    // "primary_insurer_address": {
    //   "address_attributes": {
    //     "state": primary_insurer_address.fields.state.default_value,
    //     "address1": primary_insurer_address.fields.address1.default_value,
    //     "address2": primary_insurer_address.fields.address2.default_value,
    //     "country": primary_insurer_address.fields.country.default_value,
    //     "zip": primary_insurer_address.fields.zip.default_value,
    //     "city": primary_insurer_address.fields.city.default_value,
    //   }
    // },
    "primary_physician": {
      "phone_number": primary_physician.fields.phone_number.default_value,
      "primary_doctor":  primary_physician.fields.primary_doctor.default_value,
      "primary_pharmacy": primary_physician.fields.primary_pharmacy.default_value,
      "secondary_pharmacy": primary_physician.fields.secondary_pharmacy.default_value,
    },
    "home_address_attributes": {
      "address1": home_address.fields.address1.default_value,
      "address2": home_address.fields.address2.default_value,
      "zip": home_address.fields.zip.default_value,
      "city": home_address.fields.city.default_value,
      "state": home_address.fields.state.default_value,
      "country": home_address.fields.country.default_value,
    },
    "office_address_attributes": {
      "address1": office_address.fields.address1.default_value,
      "address2": office_address.fields.address2.default_value,
      "zip": office_address.fields.zip.default_value,
      "city": office_address.fields.city.default_value,
      "state": office_address.fields.state.default_value,
      "country": office_address.fields.country.default_value,
    },
    "patient_vision_insurance_attributes": {
      "member_id": vision_insurance.fields.member_id.default_value,
      "settings_insurance_id": vision_insurance.fields.insurance.default_value,
      "insurance_plan_id": vision_insurance.fields.plan.default_value,
    },
    "patient_medical_insurance_attributes": {
      "member_id": medical_insurance.fields.member_id.default_value,
      "settings_insurance_id": medical_insurance.fields.insurance.default_value,
      "insurance_plan_id": medical_insurance.fields.plan.default_value,
    },
    "patient_secondary_insurance_attributes": {
      "member_id": medical_secondary_insurance.fields.member_id.default_value,
      "settings_insurance_id": medical_secondary_insurance.fields.insurance.default_value,
      "insurance_plan_id": medical_secondary_insurance.fields.plan.default_value,
    },
    "patient_tertiary_insurance_attributes": {
      "member_id": medical_tertiary_insurance.fields.member_id.default_value,
      "settings_insurance_id": medical_tertiary_insurance.fields.insurance.default_value,
      "insurance_plan_id": medical_tertiary_insurance.fields.plan.default_value,
    },
    "patient_vision_secondary_insurance_attributes": {
      "member_id": vision_secondary_insurance.fields.member_id.default_value,
      "settings_insurance_id": vision_secondary_insurance.fields.insurance.default_value,
      "insurance_plan_id": vision_secondary_insurance.fields.plan.default_value,
    },
    "patient_vision_tertiary_insurance_attributes": {
      "member_id": vision_tertiary_insurance.fields.member_id.default_value,
      "settings_insurance_id": vision_tertiary_insurance.fields.insurance.default_value,
      "insurance_plan_id": vision_tertiary_insurance.fields.plan.default_value,
    },
    "relation_to_insured": relation_to_insured.default_value
     
  }

  return patientData;
}

export function printDocument(id , name) {
  var HTML_Width = $(`#${id}`).width();
  var HTML_Height = $(`#${id}`).height();
  var top_left_margin = 15;
  var PDF_Width = HTML_Width+(top_left_margin*2);
  var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;
  var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;

  html2canvas($(`#${id}`)[0],{allowTaint:true, useCORS:true}).then(function(canvas) {
    canvas.getContext('2d');
    // console.log(canvas.height+"  "+canvas.width);
    var imgData = canvas.toDataURL("image/jpeg", 1.0);
    var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    
    for (var i = 1; i <= totalPDFPages; i++) { 
      pdf.addPage(PDF_Width, PDF_Height);
      pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
    }
    
    // pdf.save(`${name}`);
    window.open(pdf.output('bloburl', { filename: name }), '_blank');
  });
}


export function checkIsVisible(data){
  return data.toString() === "true"
}

export const maskDateInput = (e) => {
  var v = e.target.value;
  let inputLength = v.length;
  const key = e.key; // const {key} = event; ES6+
  if(key === "Backspace" || key === "Delete") {
    return true;
  }else if(inputLength>10){
    e.target.value = e.target.value.slice(0, 10)
  }else if(e.keyCode !== 8 && (inputLength === 2 || inputLength === 5)) {
    e.target.value += '/';
  } else if(e.keyCode !== 8 && (inputLength === 3 || inputLength === 6) && v[inputLength - 1] !== '/'){
    e.target.value = v.substring(0, v.length - 1)+'/'+e.key;
  }
}

export const wordCount = str => {
 return _.words(str).length
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function ternaryIsPresentInObj(obj1, key1, obj2, key2){
  if (_.get(obj1, key1)) {
    return _.get(obj1, key1)
  } else {
    return _.get(obj2, key2)
  }
}

export function dateAfterDays(days) {
  const today = new Date();
  let result = today.setDate(today.getDate() + parseInt(days));
  return new Date(result).setMilliseconds(0) / 1000
}

export function dateBeforeDays(days) {
  const today = new Date();
  let result = today.setDate(today.getDate() - parseInt(days));
  return new Date(result).setMilliseconds(0) / 1000;
}

export function getDecimalValues(value) {
  value = value.toString().split('.')[1];
  value = `0.${value}`;
  return Number(value)
}

export function addMonths(date, months) {
  date = new Date(date);
  date.setMonth(date.getMonth() + months);
  return new Date(date)
}

export function isDatePast(date) {
  date = new Date(date)
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
}

export function isDateInRange(date, startDate, endDate) {
  return date >= startDate && date <= endDate;
}

export function getCustomLabel(obj, key){
  return obj[key].custom_label
}

export function handleClearRefractionContactLensFields(props, field) {
  // OD OS Nodes copying
  ['od', 'os'].map(type => {
    return [
      props.dispatch(change('examRefractionContactLensForm', `${field}[${type}_sphere]`, 'pl')),
      props.dispatch(change('examRefractionContactLensForm', `${field}[${type}_cylinder]`, ' ')),
      props.dispatch(change('examRefractionContactLensForm', `${field}[${type}_axis]`, null)),
      props.dispatch(change('examRefractionContactLensForm', `${field}[${type}_add]`, null)),
      props.dispatch(change('examRefractionContactLensForm', `${field}[${type}_prism]`, null)),
      props.dispatch(change('examRefractionContactLensForm', `${field}[visual_acuity][${type}_distance]`, null)),
      props.dispatch(change('examRefractionContactLensForm', `${field}[visual_acuity][${type}_near]`, null)),
    ]
  })
  // OU Nodes copying
  props.dispatch(change('examRefractionContactLensForm', `${field}[visual_acuity][ou_distance]`, null))
  props.dispatch(change('examRefractionContactLensForm', `${field}[visual_acuity][ou_near]`, null))
  // Glass type and Note node copying
  props.dispatch(change('examRefractionContactLensForm', `${field}[glass_type]`, null))
  props.dispatch(change('examRefractionContactLensForm', `${field}[notes]`, null))
  props.dispatch(change('examRefractionContactLensForm', `${field}[is_marked_complete]`, true))
}

export function truncateString(str, allowedLength) {
  if (str.length > allowedLength) {
    return `${str.substring(0, allowedLength)}...`
  }
  else { return str }
}

export function isValidString(str) {
  // validate by checking length of str words
  let isValid = _.words(`${str}`).length > 0
  return isValid ? str : ''
}

export function isNonEmptyString(str) {
  // validate by checking for any non-whitespace character
  return str && /\S/.test(str);
}

export function getCurrentThemeColor() {
  let theme = localStorage.getItem('theme')
  switch (theme) {
    case 'theme2': return '#68c031';
    case 'theme3': return '#efa03e';
    case 'theme4': return '#a458ef';
    case 'theme5': return '#ef39e5';
    case 'theme6': return '#7acdef';
    case 'theme7': return '#efc348';
    case 'theme8': return '#000000';
    case 'theme9': return '#ff0000';
    default: return '#297ebb';
  }
}

export function examFieldModelName(field, activeTab) {
  // This method will return Backend Model Name on which the exam field relies
  if (activeTab === 9) {
    return 'Exam::AdditionalTest'
  } else {
    if (field == 'keratometry') {
      return 'Exam::Keratometry'
    }
    else if (field == 'external_exam') {
      return 'Exam::External'
    }
    else if (field == 'internal_exam') {
      return 'Exam::Internal'
    }
    else if (field == 'current_contact_lens' || field == 'contact_lens_prescription') {
      return 'Exam::Lens'
    }
    else if (field == 'subjective' || field == 'objective' || field == 'binocular_balance' || field == 'cycloplegic' || field == 'finalized_glasses' || field == 'current_glasses' || field == 'eye_glass_prescription') {
      return 'Exam::Glass'
    }
    else {
      return 'Exam::Examination'
    }
  }
}

export function examFieldsConvert(attr) {
  // This method returns the proper exam field name in which the data is stored
  const examFieldMap = {
    preliminary: 'priliminaries', current_glasses: 'current_glasses_attributes', objective: 'objective_attributes', subjective: 'subjective_attributes', cycloplegic: 'cycloplegic_attributes', binocular_balance: 'binocular_balance_attributes', keratometry: 'keratometry_attributes', current_contact_lens: 'current_lenses_attributes', external_exam: 'external_attributes', internal_exam: 'internal_attributes', contact_lens_prescription: 'finalized_lenses_attributes', eye_glass_prescription: 'finalized_glasses_attributes',
  };
  return examFieldMap[attr] || attr;
}
export function additionalTestFieldIndex(attr) {
  // As AdditionalTest attribute consists of all fields in the form of an array.
  // This method provides the index of a specific field inside additional test.
  const fieldIndexMap = {
    amsler_grid: 1, external_imaging: 2, internal_imaging: 3, oct: 4, topography: 5, punctal_plug: 6, epilation: 7, foreign_body_removal: 8, skin_tag_removal: 9, amniotic_membrane: 10, pachymetry: 11, inflamma_dry: 12, tear_lab: 13, digital_retina_wellness_screening: 14,
  };
  return fieldIndexMap[attr] || 0;
}

export function examImportFieldName(field) {
  // Fields Name as per BE import requirements
  const examFieldMap = {
    preliminary: 'priliminaries', current_contact_lens: 'current_lenses', external_exam: 'external', internal_exam: 'internal'
  };
  return examFieldMap[field] || field;
}

export function findKeyValuePairFromValue(obj, targetValue) {
  if (_.isObject(obj)) {
    for (const key in obj) {
      const value = obj[key];
      if (_.isObject(value)) {
        const result = findKeyValuePairFromValue(value, targetValue);
        if (result) {
          return { key, ...result };
        }
      } else if (value === targetValue) {
        return { key, value };
      }
    }
  }
  return null;
}

export const hasKeyValueInObj = (obj, keyName, val) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === keyName && obj[key] === val) {
        return true;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (hasKeyValueInObj(obj[key], keyName, val)) { // Added arguments for recursive call
          return true;
        }
      }
    }
  }
  return false;
};

export const hasKeyValueWithSkipKey = (obj, keyName, val, skipKey) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === skipKey) { // Check if it's the key to skip
        continue; // Skip this key and move to the next iteration
      }
      if (key === keyName && obj[key] === val) {
        return true;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (hasKeyValueWithSkipKey(obj[key], keyName, val, skipKey)) {
          return true;
        }
      }
    }
  }
  return false;
};

export function convertContactLensValues(data) {
  const keys = ['cylinder', 'optical_zone', 'tint', 'diameters', 'mf_type', 'add', 'base_curves', 'skirt_radius', 'lza1', 'lza2', 'dot', 'axis', 'spheres', 'rzd1', 'rzd2', 'material', 'lens_power', 'sag_dep', 'limbal', 'mid_peri', 'edge']
  // This method will convert contact Lens Fields string data into array separated by comma. '1,2,3' > ['1','2','3'] 
  keys.map((key) => data = { ...data, [key]: data[key]?.toString() ? data[key].toString().split(',') : data[key] })
  return data;
}

export function handleContactLensFieldsCopy(_this, pprops, sourceContactLensType, name, destination, source, formName) {
  const allFields = contactLensTypeFields[sourceContactLensType]
  allFields.map((field) => {
    if (field === 'base_curves')
      field = 'curve'
    else if (field === 'diameters')
      field = 'diameter'
    else if (field === 'spheres')
      field = 'sphere'
    const sourceFieldVal = _this.refs[`${name}[${source}_${field}]`]?.value || ''
    pprops.dispatch(change(`${formName}`, `${name}[${destination}_${field}]`, sourceFieldVal))
  })
}

export function getCurrentZoomLevel(){
  let zoomLevel = localStorage.getItem('zoom_level')
  return zoomLevel
}

export function updateKeyValNestedObj(obj, invalidValue) {
  return _.forEach(obj, (value, key) => {
    if (_.isObject(value)) {
      // Recursive call for nested objects
      updateKeyValNestedObj(value, invalidValue);
    } else if (value === invalidValue) {
      obj[key] = '';
    }
  });
}

export function getCardBrandImg(cardType,imageClasses) {
  switch (cardType) {
    case 'R':
      return <img className={imageClasses ?? 'width-2 mx-3'} src={DiscoverCardImg} />
    case 'V':
      return <img className={imageClasses ?? 'width-2 mx-3'} src={VisaCardImg} />
    case 'M':
      return <img className={imageClasses ?? 'width-2 mx-3'} src={MasterCardImg} />
    case 'X':
      return <img className={imageClasses ?? 'width-2 mx-3'} src={AmericanExpressImg} />
    case 'unionpay':
      return <img className={imageClasses ?? 'width-2 mx-3'} src={UnionPayImg} />
    case 'jcb':
      return <img className={imageClasses ?? 'width-2 mx-3'} src={JCBImg} />
    default: return <span className='mr-9' />
  }
}

export function replaceObjKeys(obj, keyMap) {
  return _.mapKeys(obj, (value, key) => keyMap[key] || key);
}

export const getModeOfNotification = (values) => {
  let arr = values && Object.keys(values).filter(item => values[item] === true)
  if (arr?.includes('no_notifications')) return 'none'
  else {
    let index = arr && arr.findIndex((item) => item == 'all_notifications')
    if (index && index !== -1) arr.splice(index, 1);
    return arr ? arr.toString() : ''
  }
}
export const getInsuranceType = (insuranceType) => {
  switch (insuranceType) {
    case 'PatientVisionInsurance':
      return 'patient_vision_insurance_attributes'
    case 'PatientMedicalInsurance':
      return 'patient_medical_insurance_attributes'
    case 'PatientSecondaryInsurance':
      return 'patient_secondary_insurance_attributes'
    case 'PatientTertiaryInsurance':
      return 'patient_tertiary_insurance_attributes'
    case 'PatientVisionSecondaryInsurance':
      return 'patient_vision_secondary_insurance_attributes'
    case 'PatientVisionTertiaryInsurance':
      return 'patient_vision_tertiary_insurance_attributes'
    default: return ''
  }
}

export const getInsuranceTypeBEName = (insuranceType) => {
  // As per BE naming of Insurance
  switch (insuranceType) {
    case 'patient_vision_insurance_attributes':
      return 'PatientVisionInsurance'
    case 'patient_medical_insurance_attributes':
      return 'PatientMedicalInsurance'
    case 'patient_secondary_insurance_attributes':
      return 'PatientSecondaryInsurance'
    case 'patient_tertiary_insurance_attributes':
      return 'PatientTertiaryInsurance'
    case 'patient_vision_secondary_insurance_attributes':
      return 'PatientVisionSecondaryInsurance'
    case 'patient_vision_tertiary_insurance_attributes':
      return 'PatientVisionTertiaryInsurance'
    default: return ''
  }
}

export const formatSecondsInMinutes = (seconds) => {
  if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}min ${remainingSeconds}sec`;
  }
  return seconds ? `${seconds} sec` : null;
}
