import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { DatePicker, DropdownList } from 'react-widgets';
import {MultiSelect} from '../../components/form/'
import { findKeyValuePairFromValue, isPresentInArray, maskDateInput } from '../../components/HelperFunctions'
import { CARD_TYPES, INSURANCE_BALANCE_DUE_STATUS } from '../Constants';
import Search from './Search'
import Alert from '../Alert';
import DateRangePicker from './DateRangePicker';

class Filter extends Component {
  onFilterChange(filter) {
    let isInvalidDateExist = findKeyValuePairFromValue(filter, 'Invalid date')
    if (isInvalidDateExist) {
      Alert.warning(`Invalid date format.`)
    }
    else
      this.props.filterChangeHook({ ...this.props.filters, ...filter })
  }

  render() {
    const { stores, filters, filterList, labelRemoved, isCurrentDateVisible, staffStoresId, dateRangePicker, isPatientRedirect, isItrustPayEnabled, transactionLogsView, paymentStatusOptions, cardTypes } = this.props
    const onlinePaymentSources = [
      { value: "online", name: 'Online' },
      { value: "device", name: 'Terminal' },
      { value: "link", name: 'Pay by text' },
    ]
    const paymentSources = transactionLogsView ? onlinePaymentSources : [
      isItrustPayEnabled ? onlinePaymentSources:[],
      { value: "cash", name: 'Cash' },
      { value: "check", name: 'Check' },
      { value: "card", name: 'Offline' }
    ].flat();

    const paymentTypes = [
      { id: "sale", name: 'Sale' },
      { id: "refund", name: 'Refund' },
      !transactionLogsView ? [
        { id: "insurance", name: 'Insurance' },
        { id: "void", name: 'Void' }] : []
    ].flat()

    return(
      <div className='d-flex flex-row align-items-end filters-width'>
        {!labelRemoved && <span className="text-nowrap">Filter By</span> }
        {
          isPresentInArray(filterList, 'paymentStores') &&
          <div className={`filter-multiselect ${!labelRemoved && 'ml-5'}`}>
            <MultiSelect
              klass="Store"
              label="Store"
              rpp={100}
              textField='name'
              valueField='id'
              isAllSelectVisible={true}
              defaultValues={staffStoresId}
              isSearchVisible="true"
              onSelectHook={storesIds => this.onFilterChange({ filter: { ...filters.filter, store_ids: storesIds } })} wrapperClasses="w-100" />
          </div>
        }
        {isPresentInArray(filterList, 'staff') &&
          <div className={`filter-multiselect ${!labelRemoved && 'ml-5'}`}>
            <MultiSelect
              klass="Staff"
              label="Created By"
              rpp={1000}
              textField='name'
              valueField='id'
              isAllSelectVisible={true}
              isSearchVisible="true"
              showAllSelected={true}
              onSelectHook={staffIds => this.onFilterChange({ filter: { ...filters.filter, staff_ids: staffIds } })} wrapperClasses="w-100" />
          </div>
        }
        {
          isPresentInArray(filterList, 'patients') &&
            <div className="filter-multiselect ml-5">
              <MultiSelect
                klass="Patient"
                label="Patient"
                // placeholder="Select Patient"
                textField='name'
                valueField='id'
                isSearchVisible="true"
                isPatientRedirect={isPatientRedirect}
                defaultValues={isPatientRedirect ? [isPatientRedirect.id] : null}
                onSelectHook={patientIds => this.onFilterChange({filter: {...filters.filter, patient: patientIds}})} wrapperClasses="w-100"/>
            </div>
        }
        {
          isPresentInArray(filterList, 'stores') &&
            <div className={`filter-multiselect ${!labelRemoved && 'ml-5'}`}>
              <MultiSelect
                data={stores}
                label="Store"
                placeholder="Select Stores"
                textField='store_name'
                valueField='id'
                isSearchVisible="true"
                onSelectHook={storesIds => this.onFilterChange({filter: {...filters.filter, store_ids: storesIds}})} wrapperClasses="w-100"/>
            </div>
        }

        {isPresentInArray(filterList, 'dateRange') &&
          <DateRangePicker onFilterChange={this.onFilterChange.bind(this)} filters={filters} dateRangePicker={dateRangePicker} />
        }

        {
          isPresentInArray(filterList, 'insurances') &&
            <div className="filter-multiselect ml-5">
              <MultiSelect
                klass="Settings::Insurance"
                label="Insurance"
                textField='name'
                valueField='id'
                isSearchVisible="true"
                onSelectHook={insurances => this.onFilterChange({filter: {...filters.filter, insurances: insurances}})} wrapperClasses="w-100"/>
            </div>
        }

        {
          isPresentInArray(filterList, 'insurancePayers') &&
            <div className="filter-multiselect ml-5">
              <MultiSelect
                klass="Settings::Insurance"
                label="Insurance Payer"
                textField='name'
                valueField='id'
                isSearchVisible="true"
                onSelectHook={insurance_payers => this.onFilterChange({filter: {...filters.filter, insurance_payers: insurance_payers}})} wrapperClasses="w-100"/>
            </div>
        }

        {
          isPresentInArray(filterList, 'orderDate') &&
          <div className="d-flex flex-column ml-5">
            <span>DOS</span>
            <div className="filter-date">
              <DatePicker format="MM/DD/YYYY" editFormat="MM/DD/YYYY" onChange={date=> this.onFilterChange({filter: {...filters.filter, order_date: Moment(date).format("YYYY/MM/DD") }})} onKeyDown={e => e.keyCode === 191 && e.preventDefault()} onKeyUp={e => maskDateInput(e)}/>
            </div>
          </div>
        }

        {
          isPresentInArray(filterList, 'paymentDate') &&
          <div className="d-flex flex-column ml-5">
            <span>DOP</span>
            <div className="filter-date">
              <DatePicker format="MM/DD/YYYY" editFormat="MM/DD/YYYY" onChange={date=> this.onFilterChange({filter: {...filters.filter, payment_date: Moment(date).format("YYYY/MM/DD") }})} onKeyDown={e => e.keyCode === 191 && e.preventDefault()} onKeyUp={e => maskDateInput(e)}/>
            </div>
          </div>
        }

        {
          isPresentInArray(filterList, 'paymentType') &&
          <div className={`filter-multiselect ml-5`}>
            <MultiSelect
              data={paymentTypes}
              label="Payment Type"
              // placeholder="Select Stores"
              valueField='id'
              textField='name'
              isSearchVisible="false"
              isAllSelectVisible
              onSelectHook={val => this.onFilterChange({filter: {...filters.filter, payment_type: val }})} wrapperClasses="w-100"/>
          </div>
        }

        {
          isPresentInArray(filterList, 'date') &&
          <div className="d-flex flex-column ml-5">
            <span>Date</span>
            <div className="filter-date">
                <DatePicker defaultValue={isCurrentDateVisible && new Date()} format="MM/DD/YYYY" editFormat="MM/DD/YYYY" onChange={date => this.onFilterChange({ filter: { ...filters.filter, date: Moment(date).format("YYYY/MM/DD") } })} onKeyDown={e => e.keyCode === 191 && e.preventDefault()} onKeyUp={e => maskDateInput(e)}/>
            </div>
          </div>
        }

        {
          isPresentInArray(filterList, 'paymentStatus') &&
          <div className={`filter-multiselect ml-5`}>
            <MultiSelect
                data={paymentStatusOptions ?? [
                { value: "pending", name: 'Pending'},
                { value: "unfinished", name: 'Unfinished'},
                { value: "finalized", name: 'Finalized' },
              ]}
              label="Status"
              // placeholder="Select Stores"
              valueField='value'
              textField='name'
              isSearchVisible="false"
              isAllSelectVisible
              onSelectHook={val => this.onFilterChange({filter: {...filters.filter, status: val }})} wrapperClasses="w-100"/>
          </div>
        }

        {
          isPresentInArray(filterList, 'claimStatus') &&
          <div className={`filter-multiselect ml-5`}>
            <MultiSelect
              data={INSURANCE_BALANCE_DUE_STATUS}
              label="Claim Status"
              // placeholder="Select Stores"
              textField='label'
              valueField='value'
              isSearchVisible="true"
              isAllSelectVisible
              onSelectHook={val => this.onFilterChange({filter: {...filters.filter, claim_status: val }})} wrapperClasses="w-100"/>
          </div>
        }

        {isPresentInArray(filterList, 'paymentSource') &&
          <div className={`filter-multiselect ml-5`}>
            <MultiSelect
              label= "Payment Source"
              // placeholder="Select Stores"
              data={paymentSources}
              key={isItrustPayEnabled}
              valueField='value'
              textField='name'
              isSearchVisible="false"
              isAllSelectVisible
              onSelectHook={val => this.onFilterChange({filter: {...filters.filter, mode_of_payment: val }})} wrapperClasses="w-100"/>
          </div>
        }

        {isPresentInArray(filterList, 'cardType') &&
          <div className='filter-multiselect dropdown-list-sm ml-5 d-flex flex-column justify-content-end'>
            <span className='font-size-16 mb-2'>Card Type</span>
            <DropdownList
              data={cardTypes ?? CARD_TYPES}
              valueField='value'
              textField={cardTypes ? 'name' : 'value'}
              onChange={val => this.onFilterChange({ filter: { ...filters.filter, card_type: val.value } })} wrapperClasses="w-100"
            />
          </div>
        }

        {
          isPresentInArray(filterList, 'checkNumber') &&
          <div className={`mx-5`}>
            <Search placeholder="Check #" onChangeHook={query => this.onFilterChange({filter: {...filters.filter, check_number: query }})}/>
          </div>
        }

        {
          isPresentInArray(filterList, 'date_of_birth') &&
          <div className="d-flex flex-column ml-5">
            <span>Date of Birth</span>
            <div className="filter-date">
              <DatePicker defaultValue={isCurrentDateVisible && new Date()} format="MM/DD/YYYY" editFormat="MM/DD/YYYY" onChange={date => this.onFilterChange({ filter: { ...filters.filter, date_of_birth: Moment(date).format("YYYY/MM/DD") } })} onKeyDown={e => e.keyCode === 191 && e.preventDefault()} onKeyUp={e => maskDateInput(e)} />
            </div>
          </div>
        }

        {
          isPresentInArray(filterList, 'reason_of_visit') &&
          <div className="filter-multiselect ml-5">
            <MultiSelect
              klass="Settings::Procedure"
              label="Reason of Visit"
              textField='name'
              valueField='id'
              isSearchVisible="true"
              onSelectHook={procedure_ids => this.onFilterChange({ filter: { ...filters.filter, reason_of_visit: procedure_ids } })} wrapperClasses="w-100" />
          </div>
        }

        {
          isPresentInArray(filterList, 'impression') &&
          <div className="filter-multiselect ml-5">
            <MultiSelect
              klass="Settings::Impression"
              label="Impression"
              textField='name'
              valueField='id'
              isSearchVisible="true"
              onSelectHook={impression_ids => this.onFilterChange({ filter: { ...filters.filter, impression: impression_ids } })} wrapperClasses="w-100" />
          </div>
        }

      </div>
    )
  }
}

const mapStateToProps = state => {
  const { filter, staffStore, auth: { user } } = state;
  let staffStoresId = [];
  user.accessible_stores?.filter(store => staffStoresId.push(store.store_id))
  return {
    staffStoresId,
    filters: filter.filters,
    dateRangePicker: filter.dateRangePicker,
    stores: staffStore ? staffStore.staff_stores : [],
    isPatientRedirect: filter.patientPaymentRedirect,
  }
}

export default connect(mapStateToProps)(Filter)
